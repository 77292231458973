import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'react-feather';
import * as styles from './Dropdown.module.scss';
import cx from 'classnames';

const Dropdown = ({ classNames, children, titleLeft, icon: Icon = Menu }) => {

  const [visible, setVisible] = useState();
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);

  return (
    <div ref={ref} className={cx(classNames, styles.dropdown)}>
      <button
        className={cx(styles.dropdownBtn, { [styles.dropdownBtnIsActive]: visible })}
        onClick={() => setVisible(!visible)}
      >
        {titleLeft && <span className={styles.dropdownBtnTitleLeft}>{titleLeft}</span>}
        <Icon />
      </button>
      {visible && (
        <ul className={cx(styles.dropdownMenu)}>
          {children}
        </ul>
      )}
    </div>
  );
};


export default Dropdown;