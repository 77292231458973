/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ParallaxProvider } from 'react-scroll-parallax';

import Nav from "./Nav";
import Footer from "./Footer";
import CookieBanner from './cookie-banner';
import "../styles/main.scss"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ParallaxProvider>
      <div className='page'>
        <div className="page-content">
          <Nav siteTitle={data.site.siteMetadata?.title || `Title`} />
          <main>{children}</main>
        </div>
        <Footer />
        <CookieBanner />
      </div>
    </ParallaxProvider>

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
