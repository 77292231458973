import * as React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

const CookieSettings = ({ submitHandler, settings }) => {
    return (
        <div className="cookieChoices">
            <h1>Manage Cookies Preferences</h1>
            <p>This site uses cookies to enhance this website: <Link to="/cookies" className="">Cookies Policy</Link></p>
            <p className="mb-2">Please set which cookies you wish to enable</p>
            <div className="cookieChoices_fixed">
                <span>Essential cookies are always enabled</span>
                <Icon.Lock />
            </div>
            <form className="basic-form" onSubmit={(e) => submitHandler(e)}>
                <div className="basic-form__switch cookieChoices_option">
                    <p className="basic-form__switch-desc">Performance Cookies</p>
                    <input type="checkbox" value="performance" defaultChecked={settings.performance} className="basic-form__switch-check" id="CookiesPerformance" name="performance" />
                    <label htmlFor="CookiesPerformance" className="basic-form__switch-label">Performance Cookies</label>
                </div>
                {/* <div className="basic-form__switch cookieChoices_option">
                    <p className="basic-form__switch-desc">Analytics Cookies</p>
                    <input type="checkbox" value="analytics" defaultChecked={settings.analytics} className="basic-form__switch-check" id="CookiesAnalytics" name="analytics" />
                    <label htmlFor="CookiesAnalytics" className="basic-form__switch-label">Analytics Cookies</label>
                </div> */}
                <button className="btn btn-primary mt-2">Save & Close</button>
            </form>
        </div>
    )
}

export default CookieSettings