import React, { Fragment, useState, useEffect } from "react"
import { Link } from "gatsby"
import { useLocation } from '@reach/router';
import PropTypes from "prop-types"
import * as styles from './Nav.module.scss';
import logo from '../../images/restorativ_logo.png'
import { X, Menu, ChevronDown, Twitter, Instagram } from 'react-feather'
import cx from 'classnames';
import Dropdown from '../Dropdown';

const Nav = ({ siteTitle }) => {

	const [menuOpen, setMenuOpen] = useState(false);

	const isActive = ({ isCurrent }) => {
		return isCurrent ? { className: `${styles.mastheadNavLink} ${styles.mastheadNavLinkActive}` } : { className: styles.mastheadNavLink }
	}

	const isPartiallyActive = ({ isPartiallyCurrent }) => {
		return isPartiallyCurrent ? { className: `${styles.mastheadNavLink} ${styles.mastheadNavLinkActive}` } : { className: styles.mastheadNavLink }
	}

	const location = useLocation();

	const isHomePage = location.pathname === '/';


	useEffect(() => {
		if (!menuOpen) {
			document.body.classList.remove('preventScroll');
		}
	}, [menuOpen])

	const toggleMenu = () => {
		if (!menuOpen) {
			setMenuOpen(true);
			setTimeout(() => document.body.classList.add('preventScroll'), 500)
		} else {
			setMenuOpen(false);
			setTimeout(() => document.body.classList.remove('preventScroll'), 500)
		}
	}


	const SolutionLinks = () => {
		return (
			<Fragment>
				<li><Link getProps={isActive} to='/solutions'>Mobile-First Platform</Link></li>
				{/* <li><Link getProps={isActive} to='/solutions/predictive_assessments'>Predictive Assessments</Link></li> */}
				{/* <li><Link getProps={isActive} to='/solutions/benefits'>Benefits</Link></li> */}
				<li><Link getProps={isActive} to='/solutions/organization'>Implementing at organisations</Link></li>
				<li><Link getProps={isActive} to='/solutions/predictive-ai'>Predictive and AI Technology</Link></li>
				<li><Link getProps={isActive} to='/solutions/outsourcing'>Outsourcing</Link></li>

			</Fragment>
		)
	}

	return (

		<nav className={cx(styles.masthead, { [styles.mastheadHome]: isHomePage })}>
			<div className={styles.mastheadBrand}>
				<Link to="/" ><img width='120' src={logo} alt={`${siteTitle} logo`} /> </Link>
			</div>
			<div className={styles.mastheadToggle}>
				<Menu onClick={() => toggleMenu()} />
			</div>
			<div className={cx(styles.mastheadNav, { [styles.mastheadNavVisible]: menuOpen })}>
				<span className={styles.mastheadCloseNav} onClick={() => toggleMenu()}><X size="28" /></span>
				<ul className={styles.mastheadNavList}>
					<li><Link to='/' getProps={isActive}>Home</Link></li>
					<li><Link to='/about' getProps={isActive}>About</Link></li>

					<li className={styles.mastheadNavListDesktop}>
						<Dropdown classNames={cx(styles.mastheadNavDropdown, 'pt-1', styles.mastheadNavLink, { [styles.mastheadNavLinkActive]: location.pathname.indexOf('/solutions') > -1 })} icon={ChevronDown} titleLeft="SOLUTIONS">
							<SolutionLinks />
						</Dropdown>
					</li>
					<li className={styles.mastheadNavListMobile}>
						<ul><SolutionLinks /></ul>
					</li>
					<li><Link to='/what-is-rj' getProps={isActive}>What is RJ?</Link></li>
					<li><Link to='/partnerships' getProps={isActive}>Partnerships</Link></li>
					<li><Link to='/blog' getProps={isPartiallyActive}>Blog</Link></li>
					<li><a className={styles.mastheadNavLink} href='https://tech.restorativ.co/'>T.E.C.H</a></li>
					<li><Link to='/contact' className="btn btn-primary">Contact Restorativ</Link></li>
					<li className="ml-1 mr-2"><a target="blank" href="https://twitter.com/restorativj"><Twitter size="20" color={isHomePage ? '#fff' : '#666666'} /></a></li>
					<li className="ml-1 mr-1"><a target="blank" href="https://www.instagram.com/restorativ_info/"><Instagram size="20" color={isHomePage ? '#fff' : '#666666'} /></a></li>
				</ul>
			</div>
		</nav>
	)
}


Nav.propTypes = {
	siteTitle: PropTypes.string,
}

Nav.defaultProps = {
	siteTitle: ``,
}

export default Nav
