import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Cookies from "universal-cookie"
import CookieSettings from "./cookie-settings"

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false)
    const [cookiePrefs, setCookiePrefs] = useState({ performance: false })
    const [showSettings, setShowSettings] = useState(false)
    const cookieVersion = "1.0"
    const cookieExpirey = 30 * 24 * 60 * 60

    const cookies = new Cookies()
    const cookie = cookies.get("rtv_prefs")

    const clearAllCookies = () => {
        let all = cookies.getAll()
        Object.entries(all).forEach(([k, v]) => {
            cookies.remove(k)
        })
    }
    const checkLegacyCookies = () => {
        if (cookie === undefined || getCookieSetting('version') !== cookieVersion) {
            clearAllCookies()
        }
    }

    const getCookieSetting = (settingName) => {
        return (cookie) ? cookie[settingName] : false
    }

    useEffect(() => {
        checkLegacyCookies()
        setShowBanner(!getCookieSetting('set'))
        setCookiePrefs({
            performance: getCookieSetting('performance'),
        })
    }, [])

    const updateCookie = (p) => {
        cookies.set(
            'rtv_prefs',
            JSON.stringify({ set: true, performance: p, version: cookieVersion }),
            { maxAge: cookieExpirey, sameSite: "lax", secure: true }
        )
    }

    const acceptCookies = () => {
        setShowBanner(false)
        updateCookie(true, true)
        window.location.reload()
    }

    const rejectCookies = () => {
        setShowBanner(false)
        updateCookie(false)
        window.location.reload()
    }

    const customCookies = (e) => {
        e.preventDefault()
        var data = new FormData(e.target)
        setShowBanner(false)
        updateCookie(data.get('performance') != null)
        window.location.reload();
    }

    return (
        <>
            {showBanner && (
                <section className="cookieBanner">
                    {!showSettings &&
                        <div className="cookieBanner_desc">
                            <div>
                                <h1 className="h3 mb-0">Cookies</h1>
                                <p>This site uses cookies to enhance this website: <Link to="/cookies" className="">Cookies Policy</Link></p>
                            </div>
                            <div className="cookieBanner_opts">
                                <button onClick={acceptCookies} className="cookieBanner_btn btn btn-primary">Accept All</button>
                                <button onClick={rejectCookies} className="cookieBanner_btn btn btn-muted">Reject All</button>
                                <button onClick={() => setShowSettings(true)} className="cookieBanner_btn btn btn-muted">Customise</button>
                            </div>
                        </div>
                    }
                    {showSettings &&
                        <CookieSettings submitHandler={(e) => customCookies(e)} settings={cookiePrefs} />
                    }
                </section>
            )}
        </>
    )
}

export default CookieBanner