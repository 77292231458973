import React from "react"
import * as styles from './Footer.module.scss';
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Footer = () => {

	return (
		<footer className={styles.footer}>
			<div className="content-wrapper">
				<StaticImage width={180} src="../../images/restorativ_logo.png" alt='Restorativ' />
				<div className={styles.footerContent}>
					<div>
						<p>Restorativ Community Interest Company</p>
						<p className="mb-3">Registered Number: 14772092</p>
						<p className="mb-1">Email us at <a href="mailto:info@restorativ.co">info@restorativ.co</a></p>
						<p>Tel: +44 (0) 203 576 4388</p>
					</div>
					<div>
						<ul>
							<li><Link to='/about'>About us</Link></li>
							<li><Link to='/contact'>Contact us</Link></li>
							<li><Link to='/terms'>Terms and conditions</Link></li>
							<li><Link to='/privacy'>Privacy Policy</Link></li>
							<li><Link to='/cookies'>Cookie Policy</Link></li>
						</ul>
					</div>
					<div>
						<ul className={styles.footerApps}>

							<li className="mb-2">
								<a target="blank" href="https://play.google.com/store/apps/details?id=com.axeedge.restorativ&pli=1">
									<StaticImage src="../../images/google-play-badge.svg" alt='Google Play' />
								</a>
							</li>
							<li>
								<a target="blank" href="https://apps.apple.com/us/app/restorativ/id1559103892">
									<StaticImage src="../../images/app-store-badge.svg" alt='App store' />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	)
}



export default Footer
